<script lang="ts" setup>
import type { HomeJackpot } from "@/types";

const DEFAULT_INCREMENT_STEP = 0.15;
const DEFAULT_INCREMENT_INTERVAL = 550;

const props = defineProps<{
	isSweepStakes: boolean;
	jackpots: HomeJackpot;
}>();

const intervalId = ref<ReturnType<typeof setInterval>>();
const amount = ref(0);
const arraybleAmount = ref<string[]>(["0"]);

const jackpotAmount = computed(() => {
	const values = props.jackpots?.[props.isSweepStakes ? "USD" : "TRN"]?.values ?? [];
	return values.reduce((acc, item) => acc + (Number(item.amount) ?? 0), 0) * 100;
});

const incrementValue = () => {
	amount.value += DEFAULT_INCREMENT_STEP;
	if (props.isSweepStakes) {
		arraybleAmount.value = numberFormat(amount.value, { minimumFractionDigits: 2 }).split("").slice(0, -3);
		return;
	}
	arraybleAmount.value = numberFormat(amount.value * 100).split("");
};

watch(jackpotAmount, () => {
	amount.value = jackpotAmount.value;
});

onMounted(() => {
	amount.value = jackpotAmount.value;
	intervalId.value = setInterval(incrementValue, DEFAULT_INCREMENT_INTERVAL);
});

onUnmounted(() => clearInterval(intervalId.value));
</script>
<template>
	<div class="jp-counter">
		<div :class="['jp-counter-wrapper', isSweepStakes ? 'color-custom-3' : 'color-custom-7']">
			<NuxtIcon :name="`32/${isSweepStakes ? 'entries' : 'coins'}`" class="coins-icon" filled />
			<TransitionGroup name="jp-counter">
				<AText
					v-for="(number, index) in arraybleAmount"
					:key="`${number}${index}`"
					:class="['jp-counter__item', { 'is-separator': isNaN(parseInt(number)) }]"
					:data-text="number"
					variant="tustan"
					:modifiers="['bold']"
				>
					{{ isNaN(parseInt(number)) ? "," : number }}
				</AText>
			</TransitionGroup>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.jp-counter {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px;

	.coins-icon {
		font-size: 70px;

		@include media-breakpoint-down(xxl) {
			font-size: 44px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 34px;
		}

		:deep(svg) {
			margin-bottom: 0;
		}
	}

	&__item {
		display: inline-block;
		position: relative;
		text-align: center;
		transition: all 0.55s;
		transition-property: opacity, transform;
		width: 48px;
		text-shadow: 0 4px 4px var(--custom-rgb-13);

		@include media-breakpoint-down(xxl) {
			font-size: 40px;
			width: 26px;
		}

		@include media-breakpoint-down(lg) {
			font-size: 32px;
			width: 22px;
		}

		&.jp-counter__item--jp-page {
			width: 18px;

			@include media-breakpoint-down(md) {
				width: 14px;
			}
		}

		&.is-separator {
			width: auto;
		}
	}

	&__dot {
		display: inline-block;
	}

	&-enter-from,
	&-leave-to {
		opacity: 0;
	}

	&-enter-from {
		transform: translateY(40px);
		margin: 0;
	}

	&-leave-to {
		transform: translateY(-40px);
	}

	&-leave-active {
		position: absolute;
	}

	&__item-img {
		max-width: 60px;
		margin-right: 12px;
	}
}
</style>
